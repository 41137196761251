import './Navigation.scss';
import { ReactComponent as Icon } from '../../Assets/Icon/Icon.svg';
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { NavHashLink } from 'react-router-hash-link';

const NavigationComponent = () => {
	const pages = [
		{ name: 'Services', url: '#services' },
		{ name: 'About Us', url: '#about' },
		{ name: 'Reviews', url: '#reviews' },
		{ name: 'Contact', url: '#contact' }
	];

	const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

	const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	return (
		<AppBar className="appbar-container">
			<Container
				maxWidth="xl"
				sx={{ padding: 0 }}
			>
				<Toolbar disableGutters>
					<Box className="business-information">
						<Icon className="business-icon" />
						<Typography
							color="primary"
							variant="h5"
							noWrap
							component="a"
							href="/"
							className="business-name"
						>
							BUTTERFLY WHISPERS
						</Typography>
					</Box>

					<Box className="mobile-wrapper">
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleOpenNavMenu}
							color="secondary"
						>
							<MenuIcon />
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
						>
							{pages.map((page, index) => (
								<MenuItem
									component={NavHashLink}
									key={index}
									to={page.url}
									onClick={handleCloseNavMenu}
								>
									<Typography textAlign="center">{page.name}</Typography>
								</MenuItem>
							))}
						</Menu>
					</Box>
					<Icon className="mobile-business-icon" />
					<Typography
						variant="h5"
						noWrap
						component="a"
						href="/"
						className="mobile-business-name"
						color="primary.main"
					>
						BUTTERFLY WHISPERS
					</Typography>
					<Box className="button-wrapper">
						{pages.map((page, index) => (
							<Button
								component={NavHashLink}
								key={index}
								to={page.url}
								variant="contained"
							>
								{page.name}
							</Button>
						))}
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	);
};

export default NavigationComponent;
