import './App.scss';
import { ThemeProvider } from '@mui/material';
import { websiteTheme } from './Themes/Theme';
import Box from '@mui/material/Box';
import HeroBanner from './Components/HeroBanner/HeroBanner';
import NavigationComponent from './Components/Navigation/Navigation';
import About from './Components/About/About';
import Services from './Components/Services/Services';
import Testimonials from './Components/Testimondials/Testimonials';
import Footer from './Components/Footer/Footer';
import ContactForm from './Components/Contact/Contact';
import { HashRouter } from 'react-router-dom';

function App() {
	return (
		<HashRouter>
			<ThemeProvider theme={websiteTheme}>
				<Box className="layout">
					<NavigationComponent />
					<HeroBanner />
					<Services />
					<About />
					<Testimonials />
					<ContactForm />
					<Footer />
				</Box>
			</ThemeProvider>
		</HashRouter>
	);
}

export default App;
