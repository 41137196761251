import { createTheme } from '@mui/material/styles';

export const websiteTheme = createTheme({
	palette: {
		primary: {
			main: '#ef799f',
			contrastText: '#ffffff',
		},
		secondary: {
			main: '#7c3c98',
			contrastText: '#ffffff',
		},
		background: {
			default: '#f3f8fc',
		},
		text: {
			primary: '#303030',
			secondary: '#d44316',
		},
		warning: {
			main: '#ff9800',
		},
	},
	typography: {
		h1: {
			fontSize: '2.5rem',
			fontWeight: 'bold',
			color: '#000',
			lineHeight: 1.2,
		},
		h2: {
			fontSize: '2.2rem',
			fontWeight: '400',
			color: '#000',
			lineHeight: 1.2,
			textTransform: 'uppercase',
			letterSpacing: '1px',
		},
		h3: {
			fontSize: '1.75rem',
			fontWeight: '400',
			color: '#7c3c98',
			lineHeight: 1.2,
			textTransform: 'uppercase',
			letterSpacing: '1px',
		},
		h4: {
			fontSize: '1.5rem',
			fontWeight: '500',
			color: '#000',
			lineHeight: 1.2,
		},
		h5: {
			fontSize: '1.25rem',
			fontWeight: '500',
			color: '#000',
			lineHeight: 1.2,
		},
		h6: {
			fontSize: '1rem',
			fontWeight: '300',
			color: '#000',
			lineHeight: 1.2,
		},
		body1: {
			fontSize: '1rem',
			color: '#000', // Set your preferred color for body text
			lineHeight: 1.5,
		},
		body2: {
			fontSize: '0.875rem',
			color: '#000',
			lineHeight: 1.5,
		},
	},

	components: {
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true,
			},
		},

		MuiPaper: {
			styleOverrides: {
				root: {
					background: '#ffffff',
				},
			},
		},
	},
});
