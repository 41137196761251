import { Card, CardContent, CardMedia, Typography, Button, Box } from '@mui/material';
import image1 from '../../Assets/Images/AdobeStock_334888160.jpeg';
import image2 from '../../Assets/Images/AdobeStock_617682920.jpeg';
import image3 from '../../Assets/Images/AdobeStock_336096676.jpeg';
import './Services.scss';

interface ReikiServiceProps {
	image: string;
	title: string;
	description: string;
}

const ReikiServiceCard: React.FC<ReikiServiceProps> = ({ image, title, description }) => {
	return (
		<Card className="service-card">
			<CardMedia
				component="img"
				alt={title}
				height="250"
				image={image}
			/>
			<CardContent>
				<Typography
					gutterBottom
					className="service-label"
				>
					{title}
				</Typography>
				<Typography className="service-description">{description}</Typography>
				<Button
					variant="contained"
					color="secondary"
				>
					Learn More
				</Button>
			</CardContent>
		</Card>
	);
};

const Services = () => {
	return (
		<Box className="service-wrapper">
			<section id="services">
			<Typography variant="h3">Services</Typography>
			<Box className="services-card-wrapper">
				<ReikiServiceCard
					image={image1}
					title="Reiki Session"
					description="Experience the profound healing energy of Reiki in a calming and soothing session."
				/>
				<ReikiServiceCard
					image={image3}
					title="Distance Healing"
					description="Experience the healing power of Reiki no matter where you are with our distance healing sessions."
				/>
				<ReikiServiceCard
					image={image2}
					title="Rahanni session"
					description="Rahanni means &quot;Of One Heart&quot; and is an effective therapy that heals deeply at heart level. Coming soon."
				/>
			</Box>
			</section>
		</Box>
	);
};

export default Services;
