// ContactForm.tsx
import React, { useState } from 'react';
import { TextField, Button, Grid, Typography, Container, Box } from '@mui/material';
import backgroundImage from '../../Assets/Images/AdobeStock_188628553.jpeg';
import "./Contact.scss"

const ContactForm: React.FC = () => {
	const [formData, setFormData] = useState({
		name: '',
		phoneNumber: '',
		email: '',
		message: '',
	});

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		console.log(formData);
	};

	return (
		<Box className="contact-form-container">
			<section id="contact">
				<Box
					className="background-image"
					style={{ backgroundImage: `url(${backgroundImage})` }}
				></Box>
				<Container maxWidth="sm">
					<Typography
						variant="h4"
						gutterBottom
					>
						Contact Us
					</Typography>
					<form onSubmit={handleSubmit}>
						<Grid
							container
							spacing={2}
						>
							<Grid
								item
								xs={12}
							>
								<TextField
									fullWidth
									label="Name"
									variant="filled"
									color="secondary"
									name="name"
									value={formData.name}
									onChange={handleChange}
								/>
							</Grid>
							<Grid
								item
								xs={12}
							>
								<TextField
									fullWidth
									label="Phone Number"
									variant="filled"
									color="secondary"
									name="phoneNumber"
									value={formData.phoneNumber}
									onChange={handleChange}
								/>
							</Grid>
							<Grid
								item
								xs={12}
							>
								<TextField
									fullWidth
									label="Email"
									variant="filled"
									color="secondary"
									name="email"
									value={formData.email}
									onChange={handleChange}
								/>
							</Grid>
							<Grid
								item
								xs={12}
							>
								<TextField
									fullWidth
									label="Message"
									variant="filled"
									color="secondary"
									name="message"
									value={formData.message}
									onChange={handleChange}
									multiline
									rows={4}
								/>
							</Grid>
						</Grid>
						<Box mt={2}>
							<Button
								variant="contained"
								color="secondary"
								type="submit"
								fullWidth
							>
								Submit
							</Button>
						</Box>
					</form>
				</Container>
			</section>
		</Box>
	);
};

export default ContactForm;
