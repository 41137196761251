import { Box, Typography } from '@mui/material';
import reiki from '../../Assets/Images/AdobeStock_273213971.jpeg';
import './About.scss';

const About = () => {
	return (
		<section id="about">
			<Box className="about-container">
				<Box className="banner-image-wrapper">
					<Box
						component={'img'}
						src={reiki}
						alt="Healer putting hands above head of a man on a massage table"
						className="about-image"
					/>
				</Box>

				<Box className="about-text-wrapper">
					<Typography
						variant="h2"
						className="about-header"
					>
						About Butterfly Whispers
					</Typography>
					<Typography
						variant="h3"
						className="about-subheading"
					>
						<i className="fa-regular fa-hand-heart about-icon"></i> Meet Our Founder, Melanie
					</Typography>
					<Typography className="about-description">
						At Butterfly Whispers, we are dedicated to the art of Reiki healing, and it all starts
						with our passionate founder, Melanie. With a warm heart and a nurturing spirit, Melanie brings
						the gift of healing to the picturesque town of Guildford. Melanie discovered the profound
						benefits of Reiki during a transformative journey of self-discovery. Her unwavering
						belief in the healing power of energy and her desire to share this gift with others led
						to the creation of Butterfly Whispers.
					</Typography>
					<Typography
						variant="h3"
						className="about-subheading"
					>
						<i className="fa-regular fa-spa about-icon"></i> Our Philosophy
					</Typography>
					<Typography className="about-description">
						Butterfly Whispers is more than just a Reiki practice; it's a sanctuary for rejuvenation
						and renewal. Guided by the gentle whispers of transformation, we believe that true
						healing comes from within. Our approach is rooted in the belief that everyone possesses
						the innate ability to restore balance, harmony, and well-being in their lives.
					</Typography>
					<Typography
						variant="h3"
						className="about-subheading"
					>
						<i className="fa-regular fa-sun about-icon"></i> A Journey of Healing
					</Typography>
					<Typography className="about-description">
						At Butterfly Whispers, we invite you to embark on a journey of self-discovery, where the
						soft flutter of a butterfly's wings can bring profound changes. Melanie is a certified
						Reiki practitioner who brings a sense of calm and serenity to each healing session.
					</Typography>
					<Typography
						variant="h3"
						className="about-subheading"
					>
						<i className="fa-regular fa-sparkles about-icon"></i> Our Commitment
					</Typography>
					<Typography className="about-description">
						We are committed to creating a safe and welcoming space where you can explore the
						transformative power of Reiki. Whether you seek relief from stress, physical ailments,
						or simply wish to align your energies, Butterfly Whispers is here to support you every
						step of the way.
					</Typography>

					<Typography
						variant="h3"
						className="about-subheading"
					>
						<i className="fa-regular fa-location-dot about-icon"></i> Located in Guildford
					</Typography>
					<Typography className="about-description">
						Nestled in the heart of Guildford, our practice is a haven of tranquility amidst the
						bustling world. We invite you to join us on this remarkable journey of healing,
						self-discovery, and empowerment. Melanie and the entire Butterfly Whispers team
						look forward to guiding you towards a harmonious life filled with positivity,
						well-being, and the gentle whispers of transformation. Discover the magic of Reiki with
						Butterfly Whispers today.
					</Typography>
				</Box>
			</Box>
		</section>
	);
};

export default About;
