import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './Testimonials.scss';
import { Box, Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import Slider from 'react-slick';

// Define TypeScript interfaces for testimonial data
interface Testimonial {
	id: number;
	text: string;
	author: string;
}

const testimonialsData: Testimonial[] = [
	{
		id: 1,
		text: 'Fusce at orci eget purus finibus vestibulum. Fusce at orci eget purus finibus vestibulum. Fusce at orci eget purus finibus vestibulum.',
		author: 'John Doe',
	},
	{
		id: 2,
		text: 'Fusce at orci eget purus finibus vestibulum. Fusce at orci eget purus finibus vestibulum. Fusce at orci eget purus finibus vestibulum.',
		author: 'Jane Smith',
	},
	{
		id: 3,
		text: 'Fusce at orci eget purus finibus vestibulum. Fusce at orci eget purus finibus vestibulum. Fusce at orci eget purus finibus vestibulum.',
		author: 'David Johnson',
	},
	{
		id: 4,
		text: 'Fusce at orci eget purus finibus vestibulum. Fusce at orci eget purus finibus vestibulum. Fusce at orci eget purus finibus vestibulum.',
		author: 'Jane Smith',
	},
	{
		id: 5,
		text: 'Fusce at orci eget purus finibus vestibulum. Fusce at orci eget purus finibus vestibulum. Fusce at orci eget purus finibus vestibulum.',
		author: 'David Johnson',
	},
];

const TestimonialCarousel: React.FC = () => {
	const settings = {
		dots: true,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
	};

	return (
		<Box className="testimonial-wrapper">
			<section id="reviews">
				<Typography
					variant="h3"
					className="testimonial-label"
				>
					Reviews
				</Typography>
				<Slider {...settings}>
					{testimonialsData.map((testimonial) => (
						<div key={testimonial.id}>
							<Card className="testimonial-card">
								<CardContent>
									<Typography variant="body1">{testimonial.text}</Typography>
									<Typography
										variant="subtitle1"
										style={{ marginTop: '1rem' }}
									>
										- {testimonial.author}
									</Typography>
								</CardContent>
							</Card>
						</div>
					))}
				</Slider>
			</section>
		</Box>
	);
};

export default TestimonialCarousel;
