import './HeroBanner.scss';
import banner from '../../Assets/Banners/Pink_Banner.svg';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { NavHashLink } from 'react-router-hash-link';

const HeroBanner = () => {
	return (
		<Box className="banner-container">
			<Box className="banner-image-wrapper">
				<Box
					component={'img'}
					src={banner}
					alt="diamond-shaped-banner"
					className="banner-image"
				/>
			</Box>

			<Box className="banner-text-wrapper">
				<Typography
					variant="h1"
					className="banner-text_header"
				>
					Embrace the Magic of Reiki with{' '}
					<Box
						component="span"
						className="company-name"
					>
						Butterfly Whispers.
					</Box>
				</Typography>
				<Typography className="company-description">
					Where Healing Meets Serenity, and Transformation Blooms in Shades of Pink. <br />
					Discover the Gentle Whispers of Healing Energy, Guiding You on Your Path to Well-being.
					<br />
					Let Butterfly Whispers Carry You on a Journey of Renewal and Inner Harmony.
				</Typography>

				<Box>
					<Button
						variant="contained"
						color="secondary"
						className="learn-more-button"
						component={NavHashLink}
						to="#about"
					>
						Learn More
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default HeroBanner;
