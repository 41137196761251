// Footer.tsx
import React from 'react';
import { Container, Typography, Link, Box, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

import './Footer.scss'; // Import the SCSS file

const Footer: React.FC = () => {
	return (
		<footer className="footer-container">
			<Container>
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
				>
					<IconButton
						className="social-icon"
						component={Link}
						href="https://www.facebook.com/your-facebook-page"
						target="_blank"
						rel="noopener noreferrer"
					>
						<FacebookIcon />
					</IconButton>
					<IconButton
						className="social-icon"
						component={Link}
						href="https://twitter.com/your-twitter-profile"
						target="_blank"
						rel="noopener noreferrer"
					>
						<TwitterIcon />
					</IconButton>
					<IconButton
						className="social-icon"
						component={Link}
						href="https://www.instagram.com/your-instagram-profile"
						target="_blank"
						rel="noopener noreferrer"
					>
						<InstagramIcon />
					</IconButton>
				</Box>
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
				>
					<Typography variant="body2">
						&copy; {new Date().getFullYear()} Butterfly Whispers
					</Typography>
				</Box>
			</Container>
		</footer>
	);
};

export default Footer;
